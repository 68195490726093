* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  outline: none;
  margin: 0;
  padding: 0;
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
}

html,
body {
  background-color: #fafbfc;
  font-size: 16px;
}
@media (max-width: 767px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  html,
  body {
    font-size: 15px;
  }
}

.full-label input {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.version {
  font-size: 0.7rem;
  text-align: right;
  color: #aaa;
  margin: 0;
  position: fixed;
  bottom: 0.3rem;
  left: 1rem;
  z-index: 0;
}